.job-list {
	display: table;
	width: 100%;
	padding: 20px 0;
	border-bottom: 1px solid #d2d2d2; 
	> div {
		display: table-cell;
		vertical-align: top;
		padding: 0 20px;
	}
	.thumbnail {
		width: 80px;
	}
	.date {
		text-align: right;
	}
	.title {
		margin-top: 0;
		font-size: 16px;
		text-transform: capitalize;
		letter-spacing: 0;
		font-weight: 400;
		margin-bottom: 5px;
	}
	.tags a {
		color: #979797;
		font-size: 14px;
	}	
	.content span + span {
		margin-left: 15px;
	}
}
footer .opaljob-button {
	margin: 30px 0;
}
.opaljob-main
+ .opaljob-button {
	margin-top: 20px;
}
.job-grid{
	overflow: hidden;
	.thumbnail{
		width: 100%;
		height: auto;

		img {
			width: 100%;
			height: auto;
		}
	}

	.content{
		padding: 15px;
		border: 1px solid #dedede;
		background: #fff;
		overflow: hidden;
		-moz-box-shadow:    inset 0 0 10px #888;
	    -webkit-box-shadow: inset 0 0 10px #888;
	    box-shadow:         inset 0 0 10px #888;
		.title{
		    font-size: 20px;
			margin: 10px 0;
		}
		.description{
			font-size: 14px;
		}
		.tags,
		.location{
		    font-size: 15px;
		    color: #888;
		}
		.expiry{
			float: right;
			font-size: 15px;
			color: #888;
		}
	}
}

.opaljob-single-job {
	.title {
		font-size: 24px;
		margin: 26px 0 0 0;
		text-transform: uppercase;
	}
	.highlight{
		font-size: 14px;
		text-transform: uppercase;
    	color: #FBBE3F;
	}
	.sub-title{
		font-size: 18px;
		margin: 20px 0;
	}
	.opaljob-main{
		background: #fff;
    	overflow: hidden;
	}
	.entry-content{
		padding: 20px 0;
		border-top: 1px solid #d2d2d2;
		border-bottom: 1px solid #d2d2d2;
	}

	.contact{
		background-color: #000;
    	padding: 50px 100px;
		h3{
			color: #fff;
			text-transform: uppercase;
			font-size: 24px;
		}	
		.details{
			border-bottom: 2px solid #666;
		}
	}
}
.description .opaljob-button {
	margin-left: 20px;
}
.opaljob-apply{
	input,textarea {
		width: 100%;
        border: 1px solid #eeeeee;
	    padding: 8px 10px 5px 15px;
	    background-color: #fff;
	}
}
.apply a {
	text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}
.opaljob-pagination{
	.page-numbers{
		list-style: none;
		margin: 20px 0 0;
		padding: 0;
		li{
			display: inline-block;
			span,
			a{
				background-color: #ebe9eb;
			    display: block;
			    padding: 5px 15px;
			    min-width: 30px;
			    min-height: 30px;
			    text-align: center;
			    color: #8a7e88;
			    transition: all 0.3s;
			    -moz-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    -ms-transition: all 0.3s;
			    -o-transition: all 0.3s;
			}
			.page-numbers{
				&:hover,
				&.current{
					background-color: #ff4a5d;
					color: #fff;
				}
			}
		}
	}
}
.opaljob-brochures-files{
	.download-link{
		display: block;
		color: #000;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		&:hover {
			color: #fbbe3f; 
		}
	}
}
.form-container{
	overflow: hidden;      
}
.opaljob-table {
	.title{
		margin: 0;
		a {
			font-size: 16px;
			line-height: 18px;
			color: #696969; 
			font-weight: 400;
			&:hover {
				color: #ff4a5d;
			}
		}
	}
	th {
		font-size: 16px;
		text-transform: uppercase;
	}
	thead tr {
		background: #312f3a;
		color: #fff;
	}
	th, td {
		padding: 15px 30px;
	}
	tbody>tr:nth-of-type(even) {
	    background-color: #f2f3f6;
	}
}